*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: 'Roboto';
}

body {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: black;
}

::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
    background: #78c0cc;
}

    ::-webkit-scrollbar-thumb:hover {
        background: #2d3e50;
    }
