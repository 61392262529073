.draganddrop {
    height:100px!important;
    max-width:100% !important;
}

.draganddrop .file-types {
    display:none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}